import { Box, Button, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { getCategoryDetail, update_catagory } from "../../../config/services/postServices";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from "../../../components/Header";
import { useState,useEffect } from "react";


const EditCatagory = () => {
  const navigate = useNavigate();
  const param = useParams();
  const categoryid = param.id;
  const [initialValues,setInitialValues] = useState({});

  useEffect(()=>{
    getCategoryDetail(categoryid).then((res)=>{

      if (res.success && res.data) {
        setInitialValues({
          category: res.data.category,
          category_am: res.data.category_am
        });
      } else {
        console.log(res.error);
      }
    });
  },[]);

  const handleFormSubmit = (values) => {
    update_catagory(values, categoryid).then(
      (res) => {
        if (res.success && res.data) {
          navigate("/catagories");
        } else {
          console.log(res.error);
        }
      }
    );
  };

  const checkoutSchema = yup.object().shape({
    category: yup.string().required("required"),
    category_am: yup.string().required("required"),
  });


  return (
    <Box
      m="20vh auto"
      width="500px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Header title="Edit Catagory" subtitle="Edit Post Catagory" />
      <Formik
      enableReinitialize={true}
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({ values, errors, touched, handleBlur, handleChange }) => (
          <Form>
            <Box
              width="500px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap="30px"
              margin="auto"
            >
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="Catagory Name English"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.category}
                name="category"
                error={!!touched.category && !!errors.category}
                helperText={touched.category && errors.category}
              />

              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="Catagory Name Amharic"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.category_am}
                name="category_am"
                error={!!touched.category_am && !!errors.category_am}
                helperText={touched.category_am && errors.category_am}
              />

              <Button type="submit" color="secondary" variant="contained">
                submit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EditCatagory;
