import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";

import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SimpleSnackbar from "../global/snackbar";
import { getRegionDropDown, updateRegionDropdown } from "../../config/services/regionServices";
 
const UpdateRegionDropDown = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const param = useParams();
  const regionId = param.id;
  const navigate = useNavigate();
  const [initialValues, setIntialValues] = useState({});

  const [snak, setsnak] = useState({
    severity: "",
    message: "",
    open: false,
  });
 

  const handleClose = () => {
    setsnak({
      open: false,
      severity: "",
      message: "",
    });
  };

  useEffect(() => {
    getRegionDropDown(regionId).then((res) => {
      if (res.success && res.data) {
        setIntialValues({
          region_name:res.data.region_name,
          region_name_am:res.data.region_name_am
        })
      } else {
        console.log(res.error);
      }
    });
  }, []);
   

  const handleFormSubmit = (values) => {
    updateRegionDropdown(values, regionId).then(
      (res) => {
        if (res.success && res.data) {
          setsnak({ severity: "success", message: res.data.message, open: true });
          navigate("/region-dropdowns");
        } else {
          setsnak({
            severity: "error",
            message: res.error,
            open: true,
          });
        }
      }
    );
  };


  const checkoutSchema = yup.object().shape({
    region_name: yup.string().required("required"),
    region_name_am: yup.string().required("required"),
  });
  
  return (
    <Box  m="10vh auto"
    width="800px"
    display="flex"
    flexDirection="column"
    justifyContent="center">
      <SimpleSnackbar
        open={snak.open}
        severity={snak.severity}
        message={snak.message}
        onClose={handleClose}
      />
      <Header title="Edit Region Dropdown" subtitle="Edit and Update Region Dropdown" />
      <Formik
        enableReinitialize={true}
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({ values, errors, touched, handleBlur, handleChange }) => (
          <Form>
            <Box
              width="800px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap="30px"
              margin="auto"
            >
              {/* <label htmlFor="name">Region Name</label> */}
              <TextField
                multiline={true}
                minRow={3}
                fullWidth
                variant="filled"
                type="text"
                label="Region Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.region_name}
                name="region_name"
                error={!!touched.region_name && !!errors.region_name}
                helperText={touched.region_name && errors.region_name}
              /> 
              {/* <label htmlFor="name">Region Name Amharic</label> */}
              <TextField
                multiline={true}
                minRow={3}
                fullWidth
                variant="filled"
                type="text"
                label="Region Name Amharic"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.region_name_am}
                name="region_name_am"
                error={!!touched.region_name_am && !!errors.region_name_am}
                helperText={touched.region_name_am && errors.region_name_am}
              /> 
              <Box m="10px"
                gap="40px"
                display="flex"
                justifyContent="center"
                alignItems="center">


                <Button type="button" onClick={() => navigate("/region-dropdowns")} m="10px" sx={{ width: "30%", height: "40px" }} color="error" variant="contained">
                  Cancel
                </Button>
                <Button type="submit" sx={{ width: "30%", height: "40px" }} color="secondary" variant="contained">
                  submit
                </Button>
              </Box>


            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default UpdateRegionDropDown;
