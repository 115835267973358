import {
  Box,
  Button,
  TextField,
} from "@mui/material";
import { blue } from '@mui/material/colors';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Header from "../../components/Header";
import SimpleSnackbar from "../global/snackbar";
import { fetchAd, updateAd } from "../../config/services/adServices";
import { fetchSupportAccount, updateSupportAccount } from "../../config/services/helpSupportServices";


const UpdateHelpSupportAccount = () => {
  const param = useParams();
  const id = param.id;

  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [telegram, setTelegram] = useState("");
  const [isActive, setIsActive] = useState(true);

  const [snak, setsnak] = useState({
    severity: "",
    message: "",
    open: false,
  });

  useEffect(() => {
    fetchSupportAccount(id).then((res) => {
      if (res.success && res.data) {
        setPhoneNumber(res.data.phone_number);
        setEmail(res.data.email);
        setTelegram(res.data.telegram)
        setIsActive(res.data.is_active);
      } else {
        console.log(res.error);
      }
    });

  }, [id]);

  const handleFormSubmit = (event) => {

    event.preventDefault();
    if(phoneNumber != "" && email != "" && telegram != "" && isActive != null){
      var formData = {"phone_number": phoneNumber,
      "email":email,"telegram":telegram,"is_active": isActive};

      updateSupportAccount(formData,id).then((res) => {
        if (res.success && res.data) {
          setsnak({ severity: "success", message: "Help & Support account Updated",  open: true });
          navigate("/help-support-accounts");
        } else {
          setsnak({
            severity: "error",
            message: res.error,
            open: true,
          });
          console.log(res.error);
        }
      });

    }else{
      setsnak({ severity: "error", message: "Please Fill All Fields",  open: true });
    }
  };

  const handleClose = () => {
    setsnak({
      open: false,
      severity: "",
      message: "",
    });
  };

  const handleCheckChange = (event) => {
    setIsActive(event.target.checked);
  };
  const handleChange = (event) => {
    if(event.target.name == "phone_number"){
      setPhoneNumber(event.target.value);
    }else if(event.target.name == "email"){
      setEmail(event.target.value);
    }else if(event.target.name == "telegram"){
      setTelegram(event.target.value);
    };
  }


  return (
    <Box
      m="15vh auto"
      width="80%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap="30px"
    >
      <SimpleSnackbar
        open={snak.open}
        severity={snak.severity}
        message={snak.message}
        onClose={handleClose}
      />
      <Box
        display="flex"
        width="60%"
        flexDirection="column"
        justifyContent="center"
        gap="30px"
        margin="auto"
      >
        <Header title="Update Support Accounts" /></Box>
        <form onSubmit={handleFormSubmit} >
        <Box
          display="flex"
          width="60%"
          flexDirection="column"
          justifyContent="center"
          gap="30px"
          margin="auto"
        >
          <TextField
            rows={5}
            fullWidth
            variant="filled"
            type="string"
            label="Phone Number"
            name="phone_number"
            value={phoneNumber}
            onChange={handleChange}
          />
          <TextField
            rows={5}
            fullWidth
            variant="filled"
            type="string"
            label="Email"
            name="email"
            value={email}
            onChange={handleChange}
          />
          <TextField
            rows={6}
            fullWidth
            variant="filled"
            type="string"
            label="Telegram"
            name="telegram"
            value={telegram}
            onChange={handleChange}
          />
          
           
          <FormGroup>
            <FormControlLabel name="is_active" control={<Checkbox checked={isActive} onChange={handleCheckChange} sx={{
              color: blue[800],
              '&.Mui-checked': {
                color: blue[600],
              },
            }} />} label="Active" />
          </FormGroup>

          <Box m="10px"
            gap="40px"
            display="flex"
            justifyContent="center"
            alignItems="center">


            <Button type="button" onClick={() => navigate("/help-support-accounts")} m="10px" sx={{ width: "30%", height: "40px" }} color="error" variant="contained">
              Cancel
            </Button>
            <Button type="submit" m="10px" sx={{ width: "30%", height: "40px" }} color="secondary" variant="contained">
              Submit
            </Button>
          </Box>
        </Box>
      </form>

    </Box>
  );
};

export default UpdateHelpSupportAccount;
