/* eslint-disable no-restricted-globals */
import { Box,useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SimpleSnackbar from "../../global/snackbar";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";

import { fetchOnlineTransactions } from "../../../config/services/paymentServices";

const OnlineTransaction = (props) => {
  // variable definations
  const navigate = useNavigate();
  const [onlineTxns, setOnlineTxns] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [snak, setsnak] = useState({
    severity: "",
    message: "",
    open: false,
  });

  // functions
  useEffect(() => {
    fetchOnlineTransactions().then((res) => {
      console.log(res.data);
      if (res.success && res.data) {
        setOnlineTxns(res.data);
      } else {
        console.log(res.error);
      }
    });
  }, []);

  const handleClose = () => {
    setsnak({
      open: false,
      severity: "",
      message: "",
    });
  };

  const columns = [
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
    },
    {
      field: "payment_type",
      headerName: "Payment Type",
      cellClassName: "name-column--cell",
      flex: 1,
    },
    
    { field: "user", headerName: "User Name", flex: 1,renderCell:(params)=> {return (params.row.user !== null ? params.row.user.code_name : 'Deleted User')} },
    // { field: "user__phone_number", headerName: "Phone Number", flex: 1 ,renderCell:(params)=> {return (params.user !== null ?  params.row.user.phone_number: 'Deleted User')}},
    { field: "credit_selected__cost", headerName: "Payment Amount", flex: 1,renderCell:(params)=> {
       return (params.row.credit_selected !== null ? params.row.currency == 'ETB' ? params.row.credit_selected.cost_in_birr : params.row.credit_selected.cost_in_usd:'Deleted Credit')} },
    { field: "credit_selected__credit", headerName: "Credit Amount",renderCell:(params)=> {return (params.row.credit_selected !== null ? params.row.credit_selected.credit: 'Deleted Credit')}},
    {
      field: "is_paid",
      headerName: "Paid Status",
      flex: 0.65,
      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            {params.row.is_paid === true && <CheckBoxRoundedIcon />}
            {params.row.is_paid === false && <DangerousOutlinedIcon />}
          </Box>
        );
      },
    },
    { field: "createdAt", headerName: "Created At" },
     
  ];

  return (
    <Box m="20px">
      <SimpleSnackbar
        open={snak.open}
        severity={snak.severity}
        message={snak.message}
        onClose={handleClose}
      />
      <Header title="Online Payment Information " subtitle="credit purchase transactions" />
 
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          // checkboxSelection
          rows={onlineTxns}
          getRowId={(rows) => rows._id}
          columns={columns}
          components={{ Toolbar: GridToolbar, }}
        />
      </Box>
    </Box>
  );
};

export default OnlineTransaction;
