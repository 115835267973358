import {
    Box,
    CircularProgress,Avatar,
    Typography,Divider,Link,Grid,Card,CardContent,useTheme
  } from "@mui/material";
  import { tokens } from "../../theme";

  import Header from "../../components/Header";
  import { useParams } from "react-router-dom";
  import { useEffect, useState } from "react";
  import {
    fetchPost,
  } from "../../config/services/postServices";
  import * as React from 'react';
import { baseUrl } from "../../config/api/apiHelpers";
  
  
  const PostDetail = (props) => {
    const param = useParams();
    const postid = param.id;
  
    const [postData,setPostData] = useState({});
    const [dataIsLoading,setDataIsLoading] = useState(true);
    const theme = useTheme();

    const colors = tokens(theme.palette.mode);

  
    useEffect(() => {
      fetchPost(postid).then((res) => {
        console.log(res.data);
        if (res.success && res.data) {
          setPostData({
            thumbnail: res.data.thumbnail,
            title: res.data.title,
            description: res.data.description,
            tags: res.data.tags,
            post_category: res.data.post_category,
            video_link: res.data.video_link,
            title_am: res.data.title_am,
            description_am: res.data.description_am,
            likes:res.data.likes,
            shares:res.data.shares,
            views:res.data.views,
            comments:res.data.comments
          });
          setDataIsLoading(false);

        } else {
          console.log(res.error);
          setDataIsLoading(false);

        }
      });
    }, [postid]);
  
 
    return (
    <Box m="25px" style={{maxHeight:"90%",overflow:"auto"}}>
    { dataIsLoading ? <>
    <center>
    <CircularProgress sx={{color:colors.greenAccent[400]}} />
    </center>
    </> : <>
    
    <Card
      sx={{
        borderRadius: 7,
        marginTop: 3,marginBottom:3,
        marginRight:3
      }}
    >
      <CardContent>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item xs={12}>
          <Avatar src={postData.thumbnail} 
                  sx={{ width: 70, height: 70 }}>
          </Avatar>

            <Typography
              component="span"
              sx={{
                fontSize: 20,
                fontWeight: 600,
                ml:2
              }}
            >
              {postData.title}
            </Typography>
            <Typography sx={{fontSize:16,color:colors.primary,ml:2}}>
            <a target="_blank" href={postData.video_link}>Click here to see the  video</a>

            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                color: "#909090",
                ml: 2
              }}
            >
              {postData.description}
            </Typography>
            

          </Grid>
        </Grid>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item xs={3}
            display='inline'>
           
            <Typography
              component="span"
              sx={{
                fontSize: 16,
                fontWeight: 300,
                ml:2
              }}
            >
              {postData.views.length} Views
            </Typography>
             
          </Grid>
          <Grid item xs={3}
            display='inline'>
           
            <Typography
              component="span"
              sx={{
                fontSize: 16,
                fontWeight: 300,
                ml:2
              }}
            >
              {postData.likes.length} Likes
            </Typography>
            </Grid>

            <Grid item xs={3}
            display='inline'>
           
           <Typography
             component="span"
             sx={{
               fontSize: 16,
               fontWeight: 300,
               ml:2
             }}
           >
             {postData.comments.length} Comments
           </Typography>
            </Grid>

           <Grid item xs={3}
            display='inline'>
           
           <Typography
             component="span"
             sx={{
               fontSize: 16,
               fontWeight: 300,
               ml:2
             }}
           >
             {postData.shares.length} Shares
           </Typography>
            
         </Grid>

        </Grid>
      </CardContent>
    </Card>
    <Card
      sx={{
        borderRadius: 7,
        marginTop: 3,marginBottom:3,
        marginRight:3
      }}
    >
      <CardContent>
      <Header title='Comments for this Post' ></Header>
        {
          postData.comments.length > 0 ? <>
          {
            postData.comments.map((comment, key) => {
              return <>
              <Grid container sx={{ alignItems: "center" }}>
              <Grid item xs={2}>
            {
                comment.user !== null ? <>
                 <Avatar src={`${baseUrl}/${comment.user.avatar}`} 
                  sx={{ width: 30, height: 30 }}>
          </Avatar>

            <Typography
              component="span"
              sx={{
                fontSize: 10,
                fontWeight: 200,
              }}
            >
              {comment.user.code_name}
            </Typography>
                </> : <> 
                <Avatar src='' 
                  sx={{ width: 30, height: 30 }}>
          </Avatar>

            <Typography
              component="span"
              sx={{
                fontSize: 10,
                fontWeight: 200,
              }}
            >
              Deleted User
            </Typography>
                </>
            }
             
            
              </Grid>
            <Grid item sx={10}>
            <Typography
              sx={{
                float:"right",
                fontSize: 15,
                color: "#909090",
                ml: 2
              }}
            >
              {comment.comment}
            </Typography>
            </Grid>
            </Grid>
            <Divider></Divider></>
            })
          }
          
          </> : <><Typography>No Comments Found</Typography></>
          
        }
      </CardContent>
    </Card>
    
    </>}
    </Box>
    );
  };
  
  export default PostDetail;
  