import { Avatar, Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import Header from "../../components/Header";
import { useEffect } from "react";
import {
  get_all_users,
  deactivate_user,
  removeAccount,
} from "../../config/services/userServices";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import SimpleSnackbar from "../global/snackbar";
import { baseUrl } from "../../config/api/apiHelpers";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const AppUsers = (props) => {
  // variable definations
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [loading,setLoading] = useState(true);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const [snak, setsnak] = useState({
    severity: "",
    message: "",
    open: false,
  });
  // functions
  const handleClose = () => {
    setsnak({
      open: false,
      severity: "",
      message: "",
    });
  };
  useEffect(() => {
    setLoading(true);
    get_all_users().then((res) => {
      console.log(res.data[0].religion.field_name);
      setLoading(false);
      if (res.success && res.data) {
        setUsers(res.data);
      } else {
        console.log(res.error);
      }
    });
  }, []);


  const manageBtnHandler = (userId) => {
    navigate(`/user-detail/${userId}/`);
  };

  const deleteHandler = (USERID) => {
    props.isloading(10);

    // eslint-disable-next-line no-restricted-globals
    if(confirm("Are you sure you want to delete this user?") === true){
      removeAccount(USERID).then((res) => {
        console.log(res.data);
        if (res.success && res.data) {
          setsnak({ severity: "success", message: res.data.message, open: true });
          setUsers(
            users.filter((value) => {
              return value._id !== USERID;
            })
          )
        } else {
          setsnak({
            severity: "error",
            message: res.error,
            open: true,
          });
          console.log(res.error);
        }
      });
    }
    props.isloading(100);
  };

  const deactivateHandler = (USERID) => {
    props.isloading(10);
     // eslint-disable-next-line no-restricted-globals
     if(confirm("Are you sure you want to do this?") === true){
    deactivate_user(USERID).then((res) => {
      if (res.success && res.data) {
        setsnak({ severity: "success", message: res.data.message, open: true });
        var index = users.findIndex(
          (item) => item._id === USERID
        );
        var newArr = [...users];
        newArr[index] = {
          ...users[index],
          'is_active': res.data.userToDeactivate.is_active,
        };
        setUsers(newArr);
      } else {
        setsnak({
          severity: "error",
          message: res.error,
          open: true,
        });
        console.log(res.error);
      }
    });}
    props.isloading(100);
  };

  const columns = [
    {
      field: "userId",
      headerName: "user Id",
      type: "number",
      headerAlign: "left",
      align: "left",
    },    
    {
      field: "code_name",
      headerName: "Code Name",
      cellClassName: "name-column--cell",
    },
    {
      field: "phone_number",
      headerName: "Phone",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field:'martial_status',
      headerName: 'Marital Status',
      renderCell:(params)=>{
        return (<Typography>{params.row.martial_status.field_name}</Typography>)
      }
    },
    {
      field:'martial_detail',
      headerName: 'Marital Detail',
      renderCell:(params)=>{
        return (<Typography>{params.row.martial_detail.field_name}</Typography>)
      }
    },
    {
      field:'hiv_status',
      headerName: 'Health Status',
      renderCell:(params)=>{
        return (<Typography>{params.row.hiv_status != null && params.row.hiv_status.field_name}</Typography>)
      }
    },
    {
      field:'region',
      headerName: 'Region',
      renderCell:(params)=>{
        return (<Typography>{params.row.region.region_name}</Typography>)
      }
    },
    {
      field:'occupation',
      headerName: 'Profession',
      renderCell:(params)=>{
        return (<Typography>{params.row.occupation.field_name}</Typography>)
      }
    },
    {
      field: "is_verified",
      headerName: "Verified",
      flex: 0.65,
      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            {params.row.is_verified === true && <CheckBoxRoundedIcon />}
            {params.row.is_verified === false && <DangerousOutlinedIcon />}
          </Box>
        );
      },
    },
    
    {
      field: "_id",
      headerName: "Manage",
      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            <Button
              onClick={() => deactivateHandler(params.row._id)}
              color={params.row.is_active ? "warning" : "secondary"}
              variant="outlined"
            >
              {params.row.is_active ? "Suspend" : "activate"}
            </Button>
            

            <Button
            onClick={() => manageBtnHandler(params.row._id)}
              color="success"
              variant="outlined"
            >
              More
            </Button>
            { params.row.deleted === true ? <Button
            onClick={() => manageBtnHandler(params.row._id)}
              color="error"
              variant="outlined"
            >
              Remove
            </Button> : <></>}
            
             
          </Box>
        );
      },
    },
  ];

  return (
    loading  ? <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      </>
      : 
      <>
    <Box m="20px">
      <SimpleSnackbar
        open={snak.open}
        severity={snak.severity}
        message={snak.message}
        onClose={handleClose}
      />
      <Header title="Users" subtitle="Managing the App Users" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          // checkboxSelection
          rows={users}
          getRowId={(row) => row._id}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          options={{
            search: true
          }}
        />
      </Box>
    </Box>
    </>
  );
};

export default AppUsers;
