import { axiosinstance  as api } from "../api/apiHelpers";


export const createPaymentAccount = async (formdata) => {
    try {
      const response = await api.post("/api/payment/create", formdata);
  
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        return { success: false, data: null, error: err.response.data.message };
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  };
  
  export const fetchOnlineTransactions = async () => {
    try {
      const response = await api.get("/api/payment/online-transactions/");
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        return { success: false, data: null, error: err.response.data.message };
      } else {
        console.log(`Error: ${err.message}`);
        return { success: false, data: null, error: err.message };
      }
    }
  };

export const fetchPaymentAccounts = async () => {
    try {
      const response = await api.get("/api/payment/all/");
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        return { success: false, data: null, error: err.response.data.message };
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  };

  export const getPaymentAccount = async (id) => {
    try {
      const response = await api.get(`/api/payment/get/${id}`);
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        return { success: false, data: null, error: err.response.data.message };
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  };

export const deletePaymentAccount = async (id) => {
    try {
      const response = await api.delete(`/api/payment/delete/${id}`);
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        return { success: false, data: null, error: err.response.data.message };
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  };

  
export const updatePaymentAccount = async (id,formData) => {
  try {
    const response = await api.put(`/api/payment/update/${id}`, formData);
    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      console.log(err.response.data);
      return { success: false, data: null, error: err.response.data.message };
    } else {
      console.log(`Error: ${err.message}`);
    }
  }
};


export const fetchPaymentInfo = async (status) => {
  try {
    const response = await api.get(`/api/payment/filter/${status}/`);
    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      console.log(err.response.data);
      return { success: false, data: null, error: err.response.data.message };
    } else {
      console.log(`Error: ${err.message}`);
    }
  }
};


export const aproveCreditPayment = async (id) => {
  try {
    const response = await api.put(`/api/payment/aprove/${id}`);
    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      console.log(err.response.data);
      return { success: false, data: null, error: err.response.data.message };
    } else {
      console.log(`Error: ${err.message}`);
    }
  }
};


export const deleteCreditPayment = async (id) => {
  try {
    const response = await api.delete(`/api/payment/delete-payment/${id}`);
    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      console.log(err.response.data);
      return { success: false, data: null, error: err.response.data.message };
    } else {
      console.log(`Error: ${err.message}`);
    }
  }
};