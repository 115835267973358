import axios from "axios";

const baseUrl = "https://mnetapi.sparktechnologygroups.com/";
// const baseUrl = "http://localhost:4000";
const axiosinstance = axios.create({
  baseURL:baseUrl,
});

// axiosinstance.defaults.headers["Authorization"] = Bearer ${auth.getToken()};
axiosinstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authTokens");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export  {axiosinstance,baseUrl};
