/* eslint-disable no-restricted-globals */
import { Box, IconButton, useTheme,Menu,MenuItem } from "@mui/material";
import { useContext,useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../config/store/auth-context";

import { ColorModeContext } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Badge from '@mui/material/Badge';
import LogoutIcon from '@mui/icons-material/Logout';
import Header2 from "../../components/Header2";


import { deleteSeenNotification, fetchUnseenNotification, 
  fetchUnseenNotificationCount } from "../../config/services/notificationService";

const Topbar = (props) => {
  const authCtx = useContext(AuthContext);
  
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [unseenCount,setUnseenCount] = useState(0);
  const [unseenList,setUnseen] = useState([]);

  useEffect(()=>{
    fetchUnseenNotificationCount().then((res) => {
      if (res.success && res.data) {
        console.log(res.data)
        setUnseenCount(res.data.unseen);
      } else {
        console.log(res.error);
      }
    });

    fetchUnseenNotification().then((res) => {
      if (res.success && res.data) {
        console.log(res.data)
        setUnseen(res.data);
      } else {
        console.log(res.error);
      }
    });
  },[]);



  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const colorMode = useContext(ColorModeContext);
  const handleLogout = () => {
      if(confirm("Are you sure you want to logout?") === true){
        authCtx.logout();
        navigate("/")

      }
  };



  const removeNotification = (id) =>{
    deleteSeenNotification(id).then((res) => {
      if (res.success && res.data) {
        setUnseen(
          unseenList.filter((value) => {
            return value._id !== id;
          })
        );
        setUnseenCount(
          unseenCount-1
        );
        navigate("/credit-payment/invalidated/")
      } else {
        navigate("/credit-payment/invalidated/")
      }
    });
  }

  return (
    <Box display="flex" justifyContent="flex-end" p={0.5}>
      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <>
        <IconButton
          size="large"
          aria-label={unseenCount > 0 ? `You have ${unseenCount} new notification` : "No new notifications"}
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={unseenCount > 0 ? handleMenu : null}
          color="inherit">
          <Badge badgeContent={unseenCount} color="error">
            <NotificationsOutlinedIcon />
          </Badge>
        </IconButton>
       
        <Menu id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                 {unseenList.map(({ _id, title, message }) => (
                  <MenuItem key={_id} onClick={()=>removeNotification(_id)}>

                      <Header2 title={title} subtitle={message} />                     
                    </MenuItem>
                   ))}
                
              </Menu>
        </>
        <IconButton size="large" onClick={()=>handleLogout()}>
          <LogoutIcon />
        </IconButton>
       
      </Box>
    </Box>
  );
};

export default Topbar;
