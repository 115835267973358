import { axiosinstance  as api } from "../api/apiHelpers";

/** 
 * Api Services for Region Dropdowns
 * 
 * as the names are descriptive 
 * the service fundctions are for 
 * create,update,get all ,get byid and delete for Dropdowns
 * 
*/
export const fetchRegionDropDowns = async () => {
    try {
      const response = await api.get("/api/dropdown/region");
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        return { success: false, data: null, error: err.response.data.message };
      } else {
        console.log(`Error: ${err.response.data.message}`);
      }
    }
  };
  
  export const getRegionDropDown = async (id) => {
    try {
      const response = await api.get(`/api/dropdown/get-region-dropdown/${id}`);
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        return { success: false, data: null, error: err.response.data.message };
      } else {
        console.log(`Error: ${err.response.data.message}`);
      }
    }
  };
  
  export const updateRegionDropdown = async (formdata, id) => {
    try {
      const response = await api.put(`/api/dropdown/update-region/${id}`, formdata);
  
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        return { success: false, data: null, error: err.response.data.message };
      } else {
        console.log(`Error: ${err.response.data.message}`);
      }
    }
  };
  
  export const updateCities = async (formData) => {
    try {
      const response = await api.put("/api/dropdown/update-city", formData);
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        return { success: false, data: null, error: err.response.data.message };
      } else {
        console.log(`Error: ${err.response.data.message}`);
      }
    }
  };
  
  export const addCities = async (id,formData) => {
    try {
      const response = await api.put(`/api/dropdown/addcity/${id}`, formData);
  
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        return { success: false, data: null, error: err.response.data.message };
      } else {
        console.log(`Error: ${err.response.data.message}`);
      }
    }
  };
  export const createRegion = async (formData) => {
    try {
      const response = await api.post("/api/dropdown/create-region", formData);
  
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        return { success: false, data: null, error: err.response.data.message };
      } else {
        console.log(`Error: ${err.response.data.message}`);
      }
    }
  };
  
  export const deleteRegion = async (id) => {
    try {
      const response = await api.delete(`/api/dropDown/delete-region/${id}`);
  
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        return { success: false, data: null, error: err.response.data.message };
      } else {
        console.log(`Error: ${err.response.data.message}`);
      }
    }
  };
  
  export const deleteCity = async (id) => {
    try {
      const response = await api.delete(`/api/dropDown/delete-city/${id}`);
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        return { success: false, data: null, error: err.response.data.message };
      } else {
        console.log(`Error: ${err.response.data.message}`);
      }
    }
  };
  