import { Box, Avatar, Divider } from "@mui/material";
import Header from "../../components/Header";
import Typography from "@mui/material/Typography";

import { useEffect } from "react";
import { useState } from "react";
import { fetchUserFeedBacks } from "../../config/services/quizeService";
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Person2Outlined } from "@mui/icons-material";


const AppFeedBack = () => {
  const [userFeedBacks, setUserFeedBacks] = useState([]);
 

  // functions
  useEffect(() => {
    fetchUserFeedBacks().then((res) => {
      console.log(res.data);
      if (res.success && res.data) {
        setUserFeedBacks(res.data);
      } else {
        console.log(res.error);
      }
    });
  }, []);


  return (
    <Box m="20px" style={{maxHeight:"90%",overflow:'auto'}}>
      <Header title="App FeedBacks" subtitle="Feedback From App Users" />
      <List marginY="40px" sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {
          userFeedBacks.map((feedBack) => {
            return (
              <Box>
              <ListItem alignItems="flex-start">
                {
                  feedBack.created_by !== null ?
                  <ListItemAvatar>
                  <Avatar alt={feedBack.created_by.code_name} src={feedBack.created_by.avatar} />
                </ListItemAvatar>:
                <ListItemAvatar>
                <Avatar alt="Deleted User" > <Person2Outlined /> </Avatar>
              </ListItemAvatar> 
                }
                
                <ListItemText
                  primary={feedBack.created_by !== null ? feedBack.created_by.code_name:"Deleted User"}
                  secondary={
                    <React.Fragment>
                      {feedBack.created_by !== null ?
                        <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {feedBack.created_by.userId}
                      </Typography>:<>Unknown</>
                      }
                      

                      <Typography>
                        {feedBack.feedback}
                        </Typography>
                    </React.Fragment>
                  }
                />
                
              </ListItem>
              <Divider></Divider>
              </Box>
            )
          })
        }
      </List>

    </Box>
  );
};

export default AppFeedBack;
