/* eslint-disable no-restricted-globals */
import { Box, Button, Avatar, useTheme, Typography,Modal } from "@mui/material";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import SimpleSnackbar from "../../global/snackbar";
import { baseUrl } from "../../../config/api/apiHelpers";
import { aproveCreditPayment, deleteCreditPayment, fetchPaymentInfo } from "../../../config/services/paymentServices";

const CreditPayments = (props) => {
  // variable definations
  const param = useParams();
  const status = param.status;
  const socket = props.socket;
  const [open, setOpen] = useState(false);
  const [imageUrl,setImageUrl]=useState("");

  const handleModalOpen = (url) => {
    setImageUrl(url);
    setOpen(true);}
  const handleModalClose = () => setOpen(false);

  const [creditPayments, setCreditPayments] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [snak, setsnak] = useState({
    severity: "",
    message: "",
    open: false,
  });

  // functions
  useEffect(() => {
    setCreditPayments({});
    fetchPaymentInfo(status).then((res) => {
      console.log("P RES.DATA.::::::",res.data);
      if (res.success && res.data) {
        setCreditPayments(res.data);
      } else {
        console.log(res.error);
      }
    });
  }, [status]);

  const handleClose = () => {
    setsnak({
      open: false,
      severity: "",
      message: "",
    });
  };

  const handleSocket = async (data) => {
    await socket.emit("paymentAproved",{
      "targetUser":data.user._id,
      "title":"Your credit purchase is aproved",
      "body":`Your ${data.credit_selected.credit} credit point purchase is aproved.`
    });
};

  const aproveHandler = (id) => {
    props.isloading(10);
    if (confirm("Are you sure you want to aprove this Credit Payment ?") === true) {
      aproveCreditPayment(id).then((res) => {
        if (res.success && res.data) {
          setsnak({ severity: "success", message: "Credit Payment Aproved", open: true });
          var index = creditPayments.findIndex(
            (item) => item._id === id
          );
          var newArr = [...creditPayments];
          newArr[index] = {
            ...creditPayments[index],
            'is_valid': res.data.is_valid,
          };
          setCreditPayments(newArr);
          handleSocket(creditPayments[index]);

        } else {
          setsnak({
            severity: "error",
            message: res.error,
            open: true,
          });
          console.log(res.error);
        }
      });
    }
    props.isloading(100);
  };

  const deleteHandler = (id) => {
    props.isloading(10);
    if (confirm("Are you sure you want to delete this Payment ?") === true) {
      deleteCreditPayment(id).then((res) => {
        if (res.success && res.data) {
          setsnak({ severity: "success", message: res.data.message, open: true });
          setCreditPayments(
            creditPayments.filter((value) => {
              return value._id !== id;
            })
          )
        } else {
          setsnak({
            severity: "error",
            message: res.error,
            open: true,
          });
          console.log(res.error);
        }
      });
    }
    props.isloading(100);
  };


  const columns = [
    {
      field: "user",
      headerName: "User",
      flex: 1,
      renderCell: (params) => {
        return (
          params.row.user !== null ? <>
            <Avatar src={`${params.row.user.avatar}`} />
            <Typography>{params.row.user.code_name}</Typography>
          </>:<>Deleted User</>
        );
      }
    },
    { field: "userId", headerName: "User ID",renderCell:(params)=>{
      return (params.row.user !== null ? <>
        <Typography>{params.row.user.userId}</Typography>
      </>:<>Deleted User</>)
    } },
    {
      field: "credit_selected", headerName: "Credit Selected", flex: 1, renderCell: (params) => {
        return (
          params.row.credit_selected !== null ?<>

            <Typography>{params.row.credit_selected.credit}  for  &nbsp;  </Typography>
            
          </>:<>Deleted Credit</>
        );
      }
    },
    { field: "txn_file", headerName: "Txn File", flex: 1,renderCell: (params) => {
      return (
        <Box width="50px" onClick={()=>handleModalOpen(params.row.txn_file)}><img  alt="Txn File"  src={params.row.txn_file} /></Box>
      );
    }},
    { field: "phone_number", headerName: "Phone", flex: 1 ,renderCell:(params)=>{
      return (params.row.user !== null ? <>
        <Typography>{params.row.user.phone_number}</Typography>
      </>:<>Deleted User</>)
    } }, 
    { field: "createdAt", headerName: "Inserted Date" },
    {
      field: "_id",
      headerName: "Manage",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            
              {params.row.is_valid === true ? <> <CheckBoxRoundedIcon /> 
              {/* <Button
              onClick={() => deleteHandler(params.row._id)}
              color="error"
              variant="outlined"
            >
              Delete
            </Button>  */}
            </>: <Button
                onClick={() => aproveHandler(params.row._id)}
                color="secondary"
                variant="outlined"
              >
                Aprove
              </Button>
              }
            
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <SimpleSnackbar
        open={snak.open}
        severity={snak.severity}
        message={snak.message}
        onClose={handleClose}
      />
      <Header title="Credit Payments" subtitle={status === "validated" ? "Aproved Credit Payments" : "New Credit Payments"} />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          // checkboxSelection
          rows={creditPayments}
          getRowId={(rows) => rows._id}
          columns={columns}
          components={{ Toolbar: GridToolbar, }}
        />
      </Box>

      <Modal
        keepMounted
        open={open}
        onClose={handleModalClose}
        aria-labelledby="Transaction Receipt"
        aria-describedby="Transaction Receipt"
      >
        <Box sx={{
          position: 'absolute',
          top: '40%',
          left: '40%',
          transform: 'translate(-50%, -50%)',
          width: 450,
          height:450,
          bgcolor: colors.blueAccent[900],
          boxShadow: 24,
          p: 4,
        }}>
          <img  alt="Txn File" height={400} width={400}  src={imageUrl} />
        </Box>
      </Modal>
    </Box>
  );
};

export default CreditPayments;
