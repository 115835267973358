import { Box, Button, Typography, useTheme } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import { useState,useEffect,useContext } from "react";
import { fetchDashBoardReport } from "../../config/services/reportService";
import AuthContext from "../../config/store/auth-context";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [reportData,setReportData] = useState({});
  const [loading,setLoading] = useState(true);
  const authCtx = useContext(AuthContext);

  useEffect( ()=>{
    setLoading(true);
     fetchDashBoardReport().then((res)=>{
      if (res.success && res.data) {
        console.log("RES DATA===================================>>>>>>>>:",res.data);
        setReportData(res.data);
        setLoading(false);        
      } else {
        console.log(res.data);
      }
    });
  },[]);
  console.log("Report Data",reportData);
   
  return (
  <>
    {
      loading  ? <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      </>
      : 
      <>
      <Box m="10px"  style={{maxHeight:"90%",overflow:'auto' }}>
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your M-net dashboard" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      
      { authCtx.role ===  "BUSSINES_ACCOUNT" ? <>
      <center><Typography>Business Account Report Pending</Typography></center>
      </>
      :
      <>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={reportData.feed_back}
            subtitle="Feedbacks Recieved"
            progress="0.75"
            // increase="+14%"
            icon={
              <EmailIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={reportData.points_given}
            subtitle="Points Given"
            progress="0.50"
            // increase="12+"
            icon={
              <PointOfSaleIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={reportData.users}
            subtitle="New Users"
            progress="0.30"
            // increase="+5%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={reportData.abuse_report}
            subtitle="Abuse Reported"
            progress="0.80"
            // increase="+43%"
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        {/* <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Revenue Generated
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                $59,342.32
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} />
          </Box>
          
        </Box> */}

        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Registered Users Based on Religion
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} data={reportData.user_by_religion} color="#70d8bd" />
          </Box>
        </Box>

        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Registered Users Based on Religion & Gender
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} data={reportData.user_by_gender_religion} color="#70d8bd" />
          </Box>
        </Box>
        
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Registered Users Based on Region
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} data={reportData.user_by_region}  color="#ce18f2" />
          </Box>
        </Box>

        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Registered Users Based on HIV Status
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} data={reportData.user_by_hiv} color="#56c46e" />
          </Box>
        </Box>

        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Registered Users Based on Gender
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} data={reportData.user_by_gender} />
          </Box>
        </Box>

        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Registered Users Based on Maritial Status
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} data={reportData.user_ms_data} />
          </Box>
        </Box>

        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Registered Users Based on Maritial Detail
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} data={reportData.user_md_data} />
          </Box>
        </Box>

        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Registered Users Based on Marriage Time Plan
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} data={reportData.user_mariage_time_data} />
          </Box>
        </Box>
      
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Registered Users Based on Education Background
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} data={reportData.user_edu_data} />
          </Box>
        </Box>
      </Box>
      </>}
    </Box>
      </>
    }
    </>
    
  );
};

export default Dashboard;
