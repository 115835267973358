import { Avatar, CircularProgress,Grid,Typography, Box, Button,Card, CardContent, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useEffect } from "react";
import {
  get_user_byid,
  removeAccount,
} from "../../config/services/userServices";

import ProfileInfo from "../../components/ProfileData";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { useState } from "react";
import { useNavigate,useParams } from "react-router-dom";
import { baseUrl } from "../../config/api/apiHelpers";
import SimpleSnackbar from "../global/snackbar";
import Header from "../../components/Header";

const AppUserDetail = (props) => {
  const navigate = useNavigate();
  const param = useParams();
  const userid = param.id
  // variable definations
  const [user, setUser] = useState({});
  const [reported, setReported] = useState([]);
  const [reportedOn, setReportedOn] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [dataIsLoading,setDataIsLoading] = useState(true);

  const [snak, setsnak] = useState({
    severity: "",
    message: "",
    open: false,
  });

  // functions
  const handleClose = () => {
    setsnak({
      open: false,
      severity: "",
      message: "",
    });
  };

  useEffect(() => {
    get_user_byid(userid).then((res) => {
      console.log("Response Data Below");
        console.log(res.data);
      if (res.success && res.data) {
        setUser(res.data.user);
        setReported(res.data.reported);
        setReportedOn(res.data.reported_on);
        setDataIsLoading(false);
      } else {
        console.log(res.error);
      }
    });
  }, []);


  const deleteHandler = (userid) => {
    props.isloading(10);

    // eslint-disable-next-line no-restricted-globals
    if(confirm("Are you sure you want to delete this user?") === true){
      removeAccount(userid).then((res) => {
        console.log(res.data);
        if (res.success && res.data) {
          setsnak({ severity: "success", message: res.data.message, open: true });
          setUser(
            user.filter((value) => {
              return value._id !== userid;
            })
          )
          navigate("/users");
        } else {
          setsnak({
            severity: "error",
            message: res.error,
            open: true,
          });
          navigate("/users");
          console.log(res.error);
        }
      });
    }
    props.isloading(100);
  };
 console.log(user);
 
  return (
    <Box m="25px" style={{maxHeight:"90%",overflow:"auto"}}>
    { dataIsLoading ? <>
    <center>
    <CircularProgress sx={{color:colors.greenAccent[400]}}/>
    </center>
    </> : <>
    <SimpleSnackbar
        open={snak.open}
        severity={snak.severity}
        message={snak.message}
        onClose={handleClose}
      /> 
<Card
      sx={{
        borderRadius: 7,
        marginTop: 3,marginBottom:3,
        marginRight:3
      }}
    >
      <CardContent>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item xs={10}>
          <Avatar src={`${baseUrl}/${user.avatar}`} 
                  sx={{ width: 70, height: 70 }}>
          </Avatar>

            <Typography
              component="span"
              sx={{
                fontSize: 20,
                fontWeight: 600,
                ml:2
              }}
            >
              {user.code_name}
            </Typography>
            <Typography
              sx={{
                fontSize: 10,
                color: "#909090",
                ml: 2
              }}
            >
              {user.userId}
            </Typography>
          </Grid>


          <Grid item xs={2} sx={{ textAlign: "center" }}>
          { user.deleted ? <>
          <Button color="error" variant="outlined"
          onClick={() => deleteHandler(user._id)}
        sx={{
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          position: "absolute",
          right: "20px",
        }}
      >Remove Account</Button>
      </>:<></> }
      
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  <Grid container spacing={2}>   
    <Grid item xs={4}>
      <Card sx={{
        borderRadius: 7,
        marginTop: 0
      }}
    >
      <CardContent>
      <List sx={{width: '100%',}} component="nav" aria-label="mailbox folders">
      <ListItem button>
        <ListItemText primary="Code Name" />
        <ListItemText primary={user.code_name}/>
      </ListItem>
      <Divider />
      <ListItem button>
        <ListItemText primary="Phone Number" />
        <ListItemText primary={user.phone_number}/>
      </ListItem>
      <Divider />
      <ListItem button>
        <ListItemText primary="Age" />
        <ListItemText primary={user.age}/>
      </ListItem>
      <Divider></Divider>
    <ListItem button>
        <ListItemText primary="App Shared" />
        <ListItemText primary={user.app_share_count}/>
      </ListItem>
      <Divider />
      <ListItem button>
        <ListItemText primary="Profile Shared" />
        <ListItemText primary={user.shares.length}/>
      </ListItem>
      <Divider />
      <ListItem button>
        <ListItemText primary="Profile Viewed" />
        <ListItemText primary={user.views.length}/>
      </ListItem>
      <Divider />
    </List>
      </CardContent>
    </Card>
  </Grid>
  <Grid item xs={8}>
       
         <Card
      sx={{
        borderRadius: 7,
        marginTop: 0
      }}
    >
      <CardContent>
        <ProfileInfo label="Gender" value={user.gender.field_name} ></ProfileInfo>
        <ProfileInfo label="Religion" value={user.religion.field_name} ></ProfileInfo>
        <ProfileInfo label="Region" value={user.region.region_name} ></ProfileInfo>
        <ProfileInfo label="City" value={user.city.city_name} ></ProfileInfo>
        <ProfileInfo label="Profession" value={user.occupation.field_name} ></ProfileInfo>
        <ProfileInfo label="Education Level" value={user.education_level.field_name} ></ProfileInfo>
        <ProfileInfo label="Martial Status" value={user.martial_status.field_name} ></ProfileInfo>
        <ProfileInfo label="Martial Detail" value={user.martial_detail.field_name} ></ProfileInfo>
        <ProfileInfo label="Height" value={user.height.field_name} ></ProfileInfo>
        <ProfileInfo label="Weight" value={user.weight.field_name} ></ProfileInfo>
        <ProfileInfo label="Skin Color" value={user.skin_color.field_name} ></ProfileInfo>
        <ProfileInfo label="Marriage Time Plan" value={user.special_prefs.field_name} ></ProfileInfo>
        <ProfileInfo label="HIV Status" value={user.hiv_status.field_name} ></ProfileInfo>
      </CardContent>
    </Card>
  </Grid>


  

    
</Grid>

<Grid container spacing={2}>   
    <Grid item xs={6}>
    <Card
      sx={{
        borderRadius: 5,
        marginTop: 3
      }}
    >
      <CardContent>
      <Header title='Abuses Reported By this user' ></Header>
        {
          reported.length > 0 ? <>
          {
            reported.map((item, key) => {
              return <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  component="span"
                  sx={{
                    fontSize: 18,
                    marginLeft: 1,
                    fontWeight: 300
                  }}
                >
                  {item.reported_by.code_name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 10,
                    ml: 1,
                    color: "#909090",
                    // width: "20%"  // You don't need to specify the width as it wil break the text in two lines
                  }}
                >
                  {item.abuse}
                </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography
                sx={{
                  float: "right",
                  fontSize: 18,
                  fontWeight: 300,
                  ml:'20px;'
                }}
              >
                ({item.reported_on.userId})
              </Typography>
              <Typography
                component="span"
                sx={{
                  float: "right",
                  fontSize: 16,
                  fontWeight: 300
                }}
              >
                {item.reported_on.code_name}
              </Typography>
              </Grid>
            </Grid>
            })
          }
          </> : <><Typography>This User hasn't reported any abuse</Typography></>
          
        }
      </CardContent>
    </Card>
  </Grid>
  <Grid item xs={6}>
       
  <Card
      sx={{
        borderRadius: 5,
        marginTop: 3
      }}
    >
      <CardContent>
      <Header title='Abuses Reported On this user' ></Header>
        { reportedOn.length > 0 ? <>
          {
            reportedOn.map((item, key) => {
              return <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  component="span"
                  sx={{
                    fontSize: 18,
                    marginLeft: 1,
                    fontWeight: 300
                  }}
                >
                  {item.reported_by.code_name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 10,
                    ml: 1,
                    color: "#909090",
                    // width: "20%"  // You don't need to specify the width as it wil break the text in two lines
                  }}
                >
                  {item.abuse}
                </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography
              sx={{
                float: "right",
                fontSize: 18,
                fontWeight: 300,
                ml:'20px;'
              }}
            >
              ({item.reported_on.userId})
            </Typography>
            <Typography
              component="span"
              sx={{
                float: "right",
                fontSize: 16,
                fontWeight: 300
              }}
            >
              {item.reported_on.code_name}
            </Typography>
              </Grid>
            </Grid>
            })
          }
        
        </>:<>
        <Typography>
          No abuse reports found on this user
        </Typography>
        </>
          
        }
      </CardContent>
    </Card>
  </Grid> 
</Grid> 
     
    </>}

      
    </Box>
  );
};

export default AppUserDetail;
