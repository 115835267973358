import { Box, Avatar, Divider,Card,Grid, CardContent } from "@mui/material";
import Header from "../../components/Header";
import Typography from "@mui/material/Typography";

import { useEffect } from "react";
import { useState } from "react";
import { fetchAbuseReports } from "../../config/services/quizeService";
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Person2 } from "@mui/icons-material";

const AbuseReport = () => {
  const [abuseReport, setAbuseReport] = useState([]);


  // functions
  useEffect(() => {
    fetchAbuseReports().then((res) => {
      console.log(res.data);
      if (res.success && res.data) {
        setAbuseReport(res.data);
      } else {
        console.log(res.error);
      }
    });
  }, []);


  return (
    <Box m="20px" //overflow: 'auto'
    style={{maxHeight: "90%",overflow: 'auto'}}>
      

      <Card sx={{
  borderRadius: 5      }}>
  <CardContent>
  <Grid container sx={{ alignItems: "center" }}>
    <Grid item xs={10}>
    <Header title="Abuse Report" subtitle="Abuse Report From App Users" />
    </Grid>

    <Grid item xs={2} sx={{ textAlign: "center" }}>
    

    </Grid>
  </Grid>
  
  <Box  style={{maxHeight:"90%",overflow:'auto' }}>
  <List marginY="40px" sx={{ width: '100%' }}>
  {
    abuseReport.map((feedBack) => {
      return (
        <Box >
          <ListItem alignItems="flex-start">
            { feedBack.reported_by !== null ? <>
              <ListItemAvatar>
              <Avatar alt={feedBack.reported_by.code_name} src={`${feedBack.reported_by.avatar}`} />
            </ListItemAvatar>
            <ListItemText
              primary={feedBack.reported_by.code_name}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {feedBack.reported_by.userId}
                  </Typography>
                  <Typography>
                    {feedBack.abuse}
                  </Typography>
                </React.Fragment>
              }
            />
            </>:<>
            <ListItemAvatar>
              <Avatar alt="delted User"> <Person2 /></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Deleted User"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >Unknown ID
                  </Typography>
                  <Typography>
                    {feedBack.abuse}
                  </Typography>
                </React.Fragment>
              }
            />
            </> }
            

            { feedBack.reported_on !== null ? <>
              <ListItemAvatar>
              <Avatar alt={feedBack.reported_on.code_name} src={`${feedBack.reported_on.avatar}`} />
            </ListItemAvatar>
            <ListItemText
              primary={feedBack.reported_on.code_name}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {feedBack.reported_on.userId}
                  </Typography>
                </React.Fragment>
              }
            />
            </>:<> <ListItemAvatar>
              <Avatar alt="delted User"> <Person2 /></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Deleted User"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >Unknown ID
                  </Typography>
                </React.Fragment>
              }
            /></> }
              
          </ListItem>
          <Divider></Divider>
        </Box>
      )
    })
  }
</List>
  </Box>
  </CardContent>
</Card>
    </Box>
  );
};

export default AbuseReport;

