import { Typography, Grid, useTheme } from "@mui/material";
import { tokens } from "../theme";

const ProfileInfo = ({ label, value }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Grid container sx={{ alignItems: "center" }}>
          <Grid item xs={10}>
            <Typography
              component="span"
              sx={{
                fontSize: 15,
                fontWeight: 300
                
              }}
              color={colors.grey[100]}

            >
              {label}
            </Typography>
           
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "center" }}>
            <Typography
                    color={colors.greenAccent[100]}

              sx={{
                fontSize: 15,
                fontWeight: 250,
                mr: 2
              }}
            >
              {value}
            </Typography>            
          </Grid>
        </Grid>
  );
};

export  default ProfileInfo;

