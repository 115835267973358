import { axiosinstance  as api } from "../api/apiHelpers";

/** 
 * Api Services related to fetching & updating notifications to the current user 
 * 
 * 

*/

export const fetchUnseenNotification = async () => {
  try {
    const response = await api.get("/api/notify/get-unseen/");
    return { success: true, data: response.data };
  } catch (err) {
    if (err.response) {
      console.log(err.response.data);
      return { success: false, data: null, error: err.response.data.message };
    } else {
      console.log(`Error: ${err.message}`);
    }
  }
};
  
export const fetchUnseenNotificationCount = async () => {
    try {
      const response = await api.get("/api/notify/unseen-count/");
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        return { success: false, data: null, error: err.response.data.message };
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  };



export const deleteSeenNotification = async (id) => {
    try {
      const response = await api.delete(`/api/notify/delete/${id}`);
      return { success: true, data: response.data };
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        return { success: false, data: null, error: err.response.data.message };
      } else {
        console.log(`Error: ${err.message}`);
        return {success:false,data:null,error:err.message}
      }
    }
  };
   
 